import axios from 'axios'

import APIController from './APIController'
import StorageController from './StorageController'

export default class CamsController {
	async handleWbr1(file: any) {
		const storageController = new StorageController()
		const data = await storageController.uploadFile(file, 'cams/wbr1/')
		try {
			const url = process.env.REACT_APP_API_URL + '/v1/mutual-funds/cams/wbr1'
			await APIController.post(url, { url: data.url })

		} catch (e) {
			// console.log(e)
		}
		await storageController.deleteFile(data.path)
	}
	async handleWbr2(file: any) {
		const storageController = new StorageController()
		const data = await storageController.uploadFile(file, 'cams/wbr2/')
		let responseData = {}
		try {
			const response = await axios.post('http://localhost:8080/', {
				data: {
					url: data.url
				}
			})
			responseData = response.data
		} catch (e) {
			// console.log(e)
		}
		await storageController.deleteFile(data.path)
		return responseData
	}
}