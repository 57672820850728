import '../styles.css'

import {
	ArrowForwardIos as ArrowForwardIosIcon,
	Chat as ChatIcon,
	Download as DownloadIcon,
	Downloading as DownloadingIcon,
	Email as EmailIcon,
	FileCopy as FileCopyIcon,
	LocalPhone as LocalPhoneIcon,
	Refresh as RefreshIcon
} from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
	Button,
	Card,
	CardHeader,
	CircularProgress,
	Grid,
	IconButton,
	SxProps,
	Tab,
	Tabs,
	Theme
} from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import BalanceView from 'components/BalanceView'
import Layout from 'components/Layout'
import { Colors } from 'models/Colors'
import { useEffect, useRef, useState } from 'react'
import React from 'react'
import { useNavigate, useParams } from 'react-router'
import { createSearchParams } from 'react-router-dom'
import { useAdminUserData } from 'services/contexts/AdminUserDataContext'
import { useFundData } from 'services/contexts/FundDataContext'
import GenerateStatementController from 'services/controllers/GenerateStatementController'
import UsersController, {
	UserData,
} from 'services/controllers/UsersController'
import copyToClipboard from 'services/util/copyToClipboard'
import currencyFormat from 'services/util/currencyFormat'

import Portfolio from '../portfolio/Portfolio'
import PortfolioMonth from '../portfolio/PortfolioMonth'
import theme from '../theme'
import Transactions from '../transactions/Transactions'
import TransactionsMonth from '../transactions/TransactionsMonth'
import TransactionsView from '../transactions/TransactionsView'
import UserBankAccountUpdateDialog from './mini-components/UserBankAccountUpdateDialog'

const drawerWidth = 240

const useStyles = (theme: Theme): { [key: string]: SxProps } => ({
	root: {
		display: 'flex',
	},
	card: {
		backgroundColor: Colors.neutrals.lead100,
		borderRadius: 2,
	},
	listPadding: {
		padding: theme.spacing(1, 0, 1, 2.9)
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		backgroundColor: Colors.neutrals.lead200,
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		backgroundColor: Colors.neutrals.lead200
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: 75,
		backgroundColor: Colors.neutrals.lead200
	},

	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 0),
		...theme.mixins.toolbar,
	},

	toolbarIcon: {
		padding: theme.spacing(0, 2.5, 0, 0.0)
	},

	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	}
}
)

const Title = (props: { text: string }) => {
	return (
		<Grid item xs={12} sm={12}>
			<Typography variant="h6">{props.text}</Typography>
		</Grid>
	)
}

const DataCard = (props: {
	title: string,
	value: string,
	classes: any,
	color?: string,
	actions?: any,
}) => {
	const className = props.classes.card
	const color = props.color ?? Colors.primary.lime400
	return (
		<Grid item xs={12} sm={3}>
			<Card
				elevation={0}
				sx={className}
				style={{ backgroundColor: color + '1A' }}
			>
				<CardHeader
					title={
						<Typography variant="body1" color={Colors.type.type100}>
							{props.title}
						</Typography>
					}
					subheader={
						<Typography variant="subtitle1" color={color}>
							{props.value}
						</Typography>
					}
					action={<>{props.actions}</>}
				></CardHeader>
			</Card>
		</Grid>
	)
}

const AccordionDataCard = (props: {
	title: string,
	value: string,
	classes: any,
	color?: string,
	actions?: any,
}) => {
	const className = props.classes.card
	const color = props.color ?? Colors.primary.lime400
	return (
		<AccordionDetails
			style={{ display: 'inline-flex', width: '25%' }}
		>
			<Card
				elevation={0}
				sx={className}
				style={{ backgroundColor: color + '1A', width: '100%' }}
			>
				<CardHeader
					title={
						<Typography variant="body1" color={Colors.type.type100}>
							{props.title}
						</Typography>
					}
					subheader={
						<Typography variant="subtitle1" color={color}>
							{props.value}
						</Typography>
					}
					action={<>{props.actions}</>}
				></CardHeader>
			</Card>
		</AccordionDetails>
	)
}

const CustomIconButton = (props: {
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined,
	icon: any,
	disabled?: boolean | undefined
}) => {
	return (
		<IconButton onClick={props.onClick} size="large" disabled={props.disabled}>
			{props.icon}
		</IconButton>
	)
}

export default function UsersView() {
	const { adminUserData } = useAdminUserData()
	const queryParams = new URLSearchParams(window.location.search)
	let _index = null
	let _transactionMonth = null
	let _portfolioMonth = null
	if (queryParams.get('index') != null) {
		try {
			_index = Number.parseInt(queryParams.get('index') ?? '0')
			if (queryParams.has('month')) {
				if (_index === 1) {
					_transactionMonth = queryParams.get('month')
				} else if (_index === 2) {
					_portfolioMonth = queryParams.get('month')
				}
			}
		} catch (error) {
			// 
		}
	}
	const formatDate = (date: any) => {
		const day = date.getDate().toString().padStart(2, '0')
		const month = (date.getMonth() + 1).toString().padStart(2, '0')
		const year = date.getFullYear()
		return `${day}/${month}/${year}`
	}

	const calculateAge = (date: any) => {
		const birthDate = new Date(date)
		const currentDate = new Date()
		let age = currentDate.getFullYear() - birthDate.getFullYear()
		const monthDiff = currentDate.getMonth() - birthDate.getMonth()
		if (monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())) {
			age--
		}
		return age
	}

	const { uid } = useParams()
	const [userData, setUserData] = useState<UserData>()
	const [statementLoading, setStatementLoading] = useState(false)
	const [showAddBankDialog, setShowAddBankDialog] = useState(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [kraLoading, setKraLoading] = useState<boolean>(false)
	const [id, setId] = useState<string>('')
	const classes = useStyles(theme)
	const usersController = new UsersController()
	const [tabIndex, setTabIndex] = useState<number>(_index ?? 0)
	const [transactionMonth, setTransactionMonth] = useState<string | null | undefined>(_transactionMonth)
	const [portfolioMonth, setPortfolioMonth] = useState<string | null | undefined>(_portfolioMonth)
	const navigate = useNavigate()
	const [fundType, setFundType] = useState<string | null>(null)
	const { fundData } = useFundData()

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		clearQuery()
		// setDrawer(false)
		setTabIndex(newValue)
	}


	// const { fundData } = useFundData()
	// const plf_ehgpg_nav = fundData?.['PLF-EHGPG'].nav
	// const plf_mcgpg_nav = fundData?.['PLF-MCGPG'].nav


	function clearQuery() {
		if (queryParams.has('index')) {
			queryParams.delete('index')
		}
		if (queryParams.has('month')) {
			queryParams.delete('month')
		}
		navigate(
			{
				search: createSearchParams(queryParams.toString()).toString(),
			},
			{ replace: true }
		)
	}

	useEffect(() => {
		const fetchData = () => {
			getUserData.current()
			getId.current()
		}
		return fetchData()
	}, [uid])

	const getUserData = useRef(() => {
		// 
	})

	getUserData.current = () => {
		if (uid !== undefined && uid !== null) {
			usersController.getUserData(uid, (data) => {
				setUserData(data)
				if (loading) {
					setLoading(false)
				}
			})
		}
	}

	const getId = useRef(() => {
		// 
	})

	getId.current = async () => {
		if (uid !== undefined && uid !== null) {
			const id = await usersController.getUserId(uid)
			setId(id)
		}
	}

	function UserDataView() { //MUTUAL FUNDS
		const mutualFunds = ['PLF-EHGPG', 'PLF-MCGPG']
		function getDataCards(fund: any): any[] {
			// console.log("XX", userData?.balance?.['PLF-EHGPG']?.balance),
			return MFCards(userData?.balance?.[fund], fundData?.[fund]?.nav)
		}
		function getFolioDataCards(fund: any, folioNum: any): any[] {
			const fundDetails = userData?.balance?.[fund]?.folios?.[folioNum]
			return MFCards(fundDetails, fundData?.[fund]?.nav)
		}

		function MFCards(fundData: any, nav: any) {
			return [
				{
					title: 'Balance',
					value: currencyFormat(nav * (fundData?.units ?? 0))
				},
				{ //test commit
					title: 'Invested',
					value: currencyFormat(fundData?.invested),
				},
				{ // added current investments to mutual funds
					title: 'Current Investments',
					value: currencyFormat(fundData?.currentInvestment),
				},
				{
					title: 'Pending Investment',
					value: currencyFormat(fundData?.pendingInvestment),
				},
				{
					title: 'Units',
					value: fundData?.units ?? 0,
				},
				{
					title: 'Daily Deposit',
					value: currencyFormat(fundData?.dailyDeposit),
				},
				{
					title: 'Total RoundUp Amount',
					value: currencyFormat(fundData?.roundup),
				},
				{
					title: 'Run Rate',
					value: currencyFormat(fundData?.runRate),
				},
				{ //Added available to withdraw to mutual funds
					title: 'Available to withdraw',
					value: currencyFormat((fundData?.balance ?? 0) - (fundData?.withdrawalInProgress ?? 0)),
				},
				{
					title: 'Total Withdrawal',
					value: currencyFormat(fundData?.totalWithdrawal),
				},
				{
					title: 'Withdrawal in Progress',
					value: currencyFormat(fundData?.withdrawalInProgress),
				},
				// {
				// 	title: "Available to Withdraw",
				// 	value: currencyFormat(fundData?.availableToWithdraw),
				// },
				{
					title: 'Total Transactions',
					value: fundData?.totalTransactions ?? 0,
				},
				{
					title: 'Total Mandates',
					value: fundData?.totalMandates ?? 0,
				},
			]
		}

		function lendboxBalanceCards(fund: string) {
			return <>

				<AccordionDataCard
					classes={classes}
					title={'Balance'}
					value={currencyFormat(userData?.balance?.[fund]?.balance ?? 0)}
				/>
				<AccordionDataCard
					classes={classes}
					title={'Invested'}
					value={currencyFormat(userData?.balance?.[fund]?.invested)}
				/>
				<AccordionDataCard
					classes={classes}
					title={'Current Investment'}
					value={currencyFormat(userData?.balance?.[fund]?.currentInvestment)}
				/>
				<AccordionDataCard
					classes={classes}
					title={'Available To Withdraw'}
					value={currencyFormat(
						userData?.balance?.[fund]?.availableToWithdraw
					)}
				/>
				<AccordionDataCard
					classes={classes}
					title={'Gains'}
					value={currencyFormat(
						userData?.balance?.[fund]?.totalInterestEarning
					)}
				/>
				<AccordionDataCard
					classes={classes}
					title={'Gains Per Day'}
					value={currencyFormat(userData?.balance?.[fund]?.interestPerDay)}
				/>
				<AccordionDataCard
					classes={classes}
					title={'Total Withdrawal'}
					value={currencyFormat(userData?.balance?.[fund]?.totalWithdrawal)}
				/>
				<AccordionDataCard
					classes={classes}
					title={'Withdrawal in Progress'}
					value={currencyFormat(
						userData?.balance?.[fund]?.withdrawalInProgress
					)}
				/>
				<AccordionDataCard
					classes={classes}
					title={'Run Rate (30 days)'}
					value={currencyFormat(userData?.balance?.[fund]?.runRate)}
				/>
				<AccordionDataCard
					classes={classes}
					title={'Total Mandates Executed'}
					value={(userData?.balance?.[fund]?.totalMandates ?? 0).toString()}
				/>

				<AccordionDataCard
					classes={classes}
					title={'Generate Statement'}
					value={statementLoading ? 'Downloading...' : 'Download'}
					actions={
						<CustomIconButton
							onClick={async () => {
								setStatementLoading(true)
								try {
									const firstName = userData?.firstName
									if (uid != null && uid != undefined && firstName != null && firstName != undefined) {
										await GenerateStatementController.downloadStatement(
											{
												uid: uid,
												fundType: fund
											}
										)
									}
								} catch (error) {
									// console.log(error)
									alert('Failed to generate statement')
								} finally {
									setStatementLoading(false)
								}
							}}
							disabled={statementLoading}
							icon={statementLoading ? <DownloadingIcon /> : <DownloadIcon />}
						/>
					}
				/>
			</>
		}

		return (
			<>
				{
					adminUserData?.isFullUsersAccess && (<>
						<Title text={'Actions'} />
						<DataCard
							classes={classes}
							title={'Firebase'}
							value={'Open Firebase Data'}
							actions={
								<CustomIconButton
									onClick={() => {
										window.open(
											`https://console.firebase.google.com/u/0/project/deciml-prod/firestore/data/~2Fusers~2F${uid}`
										)
									}}
									icon={<ArrowForwardIosIcon />}
								/>
							}
						/>
						<DataCard
							classes={classes}
							title={'Update Bank Account'}
							value={'Update Bank Account for Mutual Fund'}
							actions={
								<CustomIconButton
									onClick={() => {
										setShowAddBankDialog(true)
									}}
									icon={<ArrowForwardIosIcon />}
								/>
							}
						/>
					</>)}
				<Title text={'Basic Details'} />
				<DataCard
					classes={classes}
					title={'UID'}
					value={userData?.uid ?? ''}
					actions={
						<CustomIconButton
							onClick={() => copyToClipboard(userData?.uid ?? '')}
							icon={<FileCopyIcon />}
						/>
					}
				/>
				<DataCard
					classes={classes}
					title={'ID'}
					value={id ?? ''}
					actions={
						<CustomIconButton
							onClick={() => copyToClipboard(id ?? '')}
							icon={<FileCopyIcon />}
						/>
					}
				/>
				<DataCard
					classes={classes}
					title={'First Name'}
					value={userData?.firstName ?? ''}
					actions={
						<CustomIconButton
							onClick={() => copyToClipboard(userData?.firstName ?? '')}
							icon={<FileCopyIcon />}
						/>
					}
				/>
				<DataCard
					classes={classes}
					title={'Last Name'}
					value={userData?.lastName ?? ''}
					actions={
						<CustomIconButton
							onClick={() => copyToClipboard(userData?.lastName ?? '')}
							icon={<FileCopyIcon />}
						/>
					}
				/>
				<DataCard
					classes={classes}
					title={'Phone'}
					value={userData?.phone ?? ''}
					actions={
						userData?.phone != null ? (
							<>
								<CustomIconButton
									onClick={() =>
										window.open(
											'https://wa.me/send?phone=' +
											(userData?.phone ?? '').substring(1)
										)
									}
									icon={<ChatIcon />}
								/>
								<CustomIconButton
									onClick={() => window.open('tel:' + (userData?.phone ?? ''))}
									icon={<LocalPhoneIcon />}
								/>
								<CustomIconButton
									onClick={() => copyToClipboard(userData?.phone ?? '')}
									icon={<FileCopyIcon />}
								/>
							</>
						) : (
							<></>
						)
					}
				/>
				<DataCard
					classes={classes}
					title={'Email'}
					value={userData?.email ?? ''}
					actions={
						userData?.email != null ? (
							<>
								<CustomIconButton
									onClick={() =>
										window.open('mailto:' + (userData?.email ?? ''))
									}
									icon={<EmailIcon />}
								/>
								<CustomIconButton
									onClick={() => copyToClipboard(userData?.email ?? '')}
									icon={<FileCopyIcon />}
								/>
							</>
						) : (
							<></>
						)
					}
				/>

				<DataCard
					classes={classes}
					title={'Gender'}
					value={userData?.gender ?? ''}
				/>
				<DataCard
					classes={classes}
					title={'Date of Birth'}
					value={userData?.dob ? `${formatDate(new Date(userData.dob))} (Age: ${calculateAge(new Date(userData.dob))})` : ''}
				/>

				<Title text={'Other Details'} />
				<DataCard
					classes={classes}
					title={'Account Paused'}
					value={(userData?.accountPaused ?? false).toString()}
					color={
						userData?.accountPaused ?? false
							? Colors.system.negativeRed400
							: Colors.system.positiveGreen400
					}
					actions={
						<CustomIconButton
							onClick={() => navigate(`/users/${userData?.uid}/timestamps`)}
							icon={<ArrowForwardIosIcon />}
						/>
					}



				/>
				<DataCard
					classes={classes}
					title={'Account Deactivated'}
					value={(userData?.accountDeactivated ?? false).toString()}
					color={
						userData?.accountDeactivated ?? false
							? Colors.system.negativeRed400
							: Colors.system.positiveGreen400
					}
				/>
				<DataCard
					classes={classes}
					title={'Account Disabled'}
					value={(userData?.accountDisabled ?? false).toString()}
					color={
						userData?.accountDisabled ?? false
							? Colors.system.negativeRed400
							: Colors.system.positiveGreen400
					}
				/>


				<DataCard
					classes={classes}
					title={'SMS Permission'}
					value={(userData?.smsPermission ?? false).toString()}
					color={
						userData?.smsPermission ?? false
							? Colors.system.positiveGreen400
							: Colors.system.negativeRed400
					}
				/>
				{adminUserData?.isFullUsersAccess && (
					<DataCard
						classes={classes}
						title={'Active Days'}
						value={userData?.activeDays?.toString() ?? ''}
						actions={
							<CustomIconButton
								onClick={() =>
									copyToClipboard(userData?.activeDays.toString() ?? '')
								}
								icon={<FileCopyIcon />}
							/>
						}
					/>)}
				<DataCard classes={classes} title={'OS'} value={userData?.os ?? ''} />
				<DataCard
					classes={classes}
					title={'Version'}
					value={userData?.version ?? ''}
				/>
				<DataCard
					classes={classes}
					title={'Install Version'}
					value={userData?.installVersion ?? ''}
				/>
				<DataCard
					classes={classes}
					title={'Updated At'}
					value={userData?.updatedAt?.toString() ?? ''}
					actions={
						<CustomIconButton
							onClick={() =>
								copyToClipboard(userData?.updatedAt?.toString() ?? '')
							}
							icon={<FileCopyIcon />}
						/>
					}
				/>
				<DataCard
					classes={classes}
					title={'Created At'}
					value={userData?.createdAt?.toString() ?? ''}
					actions={
						<CustomIconButton
							onClick={() =>
								copyToClipboard(userData?.createdAt?.toString() ?? '')
							}
							icon={<FileCopyIcon />}
						/>
					}
				/>
				{adminUserData?.isFullUsersAccess && (
					<DataCard
						classes={classes}
						title={'uuid'}
						value={userData?.uuid ?? ''}
						actions={
							<>
								<CustomIconButton
									onClick={() => copyToClipboard(userData?.uuid ?? '')}
									icon={<FileCopyIcon />}
								/>
								<CustomIconButton
									onClick={() => {
										window.open(
											`https://console.firebase.google.com/u/0/project/deciml-prod/firestore/data/~2Fuuid~2F${userData?.uuid ?? ''}`
										)
									}}
									icon={<ArrowForwardIosIcon />}
								/>
							</>
						}
					/>)
				}
				<Title text={'Limits'} />
				<DataCard
					classes={classes}
					title={'Round Up Limit'}
					value={currencyFormat(userData?.roundup)}
				/>
				<DataCard
					classes={classes}
					title={'Daily Deposit Limit'}
					value={currencyFormat(userData?.recurring) ?? ''}
				/>
				<Title text={'Onboarding'} />
				<DataCard
					classes={classes}
					title={'Fund Type'}
					value={userData?.fundType ?? ''}
				/>
				<DataCard
					classes={classes}
					title={'KRA Status'}
					value={String(userData?.onboarding?.kraStatus ?? '')}
					color={userData?.onboarding?.kraStatus === true ? Colors.system.positiveGreen400 : (userData?.onboarding?.kraStatus === false ? Colors.system.negativeRed400 : Colors.primary.lime400)}
				/>
				{adminUserData?.isFullUsersAccess && (<>
					<DataCard
						classes={classes}
						title={'KRA Status Code'}
						value={userData?.kraStatusCode ?? ''}
					/>
					<DataCard
						classes={classes}
						title='KRA'
						value={'Refresh KRA Status'}
						actions={
							<CustomIconButton
								onClick={async () => {
									if (!kraLoading && uid != null) {
										setKraLoading(true)
										try {
											await usersController.refreshKRAStatus(uid)
										}
										catch (error) {
											alert('Failed to refresh KRA status')
										}
										setKraLoading(false)
									}
								}}
								icon={kraLoading ? <CircularProgress /> : <RefreshIcon />}
							/>
						}
					/>
				</>)}
				<Title text={'Lendbox'} />

				<DataCard
					classes={classes}
					title={'Lendbox KYC'}
					value={(userData?.onboarding?.lendbox ?? false).toString()}
					color={
						(userData?.onboarding?.lendbox ?? false)
							? Colors.system.positiveGreen400
							: Colors.system.negativeRed400
					}
				/>
				<DataCard
					classes={classes}
					title={'Yesbank Mandate'}
					value={(
						userData?.onboarding?.yesbankMandateCompleted ?? false
					).toString()}
					color={
						(userData?.onboarding?.yesbankMandateCompleted ?? false)
							? Colors.system.positiveGreen400
							: Colors.system.negativeRed400
					}
				/>
				<DataCard
					classes={classes}
					title={'Yesbank Mandate Limit'}
					value={(userData?.mandateLimit?.yesbank ?? false).toString()}
					color={
						(userData?.onboarding?.pennyDrop ?? false)
							? Colors.system.positiveGreen400
							: Colors.system.negativeRed400
					}
				/>
				<DataCard
					classes={classes}
					title={'Lendbox Bank Account Added'}
					value={(
						userData?.onboarding?.lendboxBankDetailsAdded ?? false
					).toString()}
					color={
						(userData?.onboarding?.lendboxBankDetailsAdded ?? false)
							? Colors.system.positiveGreen400
							: Colors.system.negativeRed400
					}
				/>
				<DataCard
					classes={classes}
					title={'Yesbank Mandate Paused'}
					value={(
						userData?.onboarding?.yesbankMandatePaused ?? false
					).toString()}
					color={
						(userData?.onboarding?.yesbankMandatePaused ?? false)
							? Colors.system.negativeRed400
							: Colors.system.positiveGreen400
					}
				/>
				<DataCard
					classes={classes}
					title={'Lendbox Investment Preference Updated'}
					value={(
						userData?.onboarding?.lendboxInvestmentPreferenceUpdated ?? false
					).toString()}
					color={
						(userData?.onboarding?.lendboxInvestmentPreferenceUpdated ?? false)
							? Colors.system.positiveGreen400
							: Colors.system.negativeRed400
					}
				/>
				<DataCard
					classes={classes}
					title={'Lendbox Investment Preference Updated Timestamp'}
					value={(
						userData?.lendboxInvestmentPreference?.timestamp ?? ''
					).toString()}
				/>
				<DataCard
					classes={classes}
					title={'Lendbox Fixed Forced'}
					value={(
						userData?.onboarding?.lendboxFixedForced ?? false
					).toString()}
					color={
						(userData?.onboarding?.lendboxFixedForced ?? false)
							? Colors.system.negativeRed400
							: Colors.system.positiveGreen400
					}
				/>
				<DataCard
					classes={classes}
					title={'Generate Statement'}
					value={statementLoading ? 'Downloading...' : 'Download'}
					actions={
						<CustomIconButton
							onClick={async () => {
								setStatementLoading(true)
								try {
									const firstName = userData?.firstName
									if (uid != null && uid != undefined && firstName != null && firstName != undefined) {
										await GenerateStatementController.downloadStatement(
											{
												uid: uid,
												allFunds: true
											}
										)
									}
								} catch (error) {
									// console.log(error)
									alert('Failed to generate statement')
								} finally {
									setStatementLoading(false)
								}
							}}
							disabled={statementLoading}
							icon={statementLoading ? <DownloadingIcon /> : <DownloadIcon />}
						/>
					}
				/>
				<Title text={'Mutual Fund'} />

				<DataCard
					classes={classes}
					title={'KYC'}
					value={(userData?.onboarding?.kyc ?? false).toString()}
					color={
						userData?.onboarding?.kyc ?? false
							? Colors.system.positiveGreen400
							: Colors.system.negativeRed400
					}
				/>
				<DataCard
					classes={classes}
					title={'CKYC'}
					value={(userData?.onboarding?.ckyc ?? false).toString()}
					color={
						(userData?.onboarding?.ckyc ?? false)
							? Colors.system.positiveGreen400
							: Colors.system.negativeRed400
					}
				/>
				<DataCard
					classes={classes}
					title={'FATCA'}
					value={(userData?.onboarding?.fatca ?? false).toString()}
					color={
						(userData?.onboarding?.fatca ?? false)
							? Colors.system.positiveGreen400
							: Colors.system.negativeRed400
					}
				/>
				<DataCard
					classes={classes}
					title={'Nominee'}
					value={(userData?.onboarding?.nominee ?? false).toString()}
					color={
						(userData?.onboarding?.nominee ?? false)
							? Colors.system.positiveGreen400
							: Colors.system.negativeRed400
					}
				/>
				<DataCard
					classes={classes}
					title={'Bank Account Added'}
					value={(userData?.onboarding?.pennyDrop ?? false).toString()}
					color={
						(userData?.onboarding?.pennyDrop ?? false)
							? Colors.system.positiveGreen400
							: Colors.system.negativeRed400
					}
				/>
				<DataCard
					classes={classes}
					title={'Juspay Mandate Done'}
					value={(
						userData?.onboardingJuspayMandateCompleted ?? false
					).toString()}
					color={
						(userData?.onboardingJuspayMandateCompleted ?? false)
							? Colors.system.positiveGreen400
							: Colors.system.negativeRed400
					}
				/>
				<DataCard
					classes={classes}
					title={'Juspay Mandate Limit'}
					value={(userData?.mandateLimit?.juspay ?? false).toString()}
					color={
						(userData?.onboarding?.pennyDrop ?? false)
							? Colors.system.positiveGreen400
							: Colors.system.negativeRed400
					}
				/>
				<Title text={'Batch'} />
				{adminUserData?.isFullUsersAccess && (<>
					<DataCard
						classes={classes}
						title={'Lendbox'}
						value={(userData?.batch?.lendbox ?? 'NA').toString()}
					/>
					<DataCard
						classes={classes}
						title={'Yesbank'}
						value={(userData?.batch?.yesbank ?? 'NA').toString()}
					/>
				</>)}
				<DataCard
					classes={classes}
					title={'Juspay'}
					value={(userData?.batch?.juspay ?? 'NA').toString()}
				/>
				<DataCard
					classes={classes}
					title={'Juspay Include'}
					value={(userData?.batch?.juspayInclude ?? 'NA').toString()}
				/>
				<DataCard
					classes={classes}
					title={'Juspay Exclude'}
					value={(userData?.batch?.juspayExclude ?? 'NA').toString()}
				/>
				<Title text={'View Data'} />
				<DataCard
					classes={classes}
					title={'Lendbox Data'}
					value={'View Lendbox Data'}
					actions={
						<CustomIconButton
							onClick={() => navigate(`/users/${userData?.uid}/lendbox`)}
							icon={<ArrowForwardIosIcon />}
						/>
					}
				/>
				{adminUserData?.isFullUsersAccess && (
					<>
						<DataCard
							classes={classes}
							title={'Mutual Fund Data'}
							value={'View Mutual Fund Data'}
							actions={
								<CustomIconButton
									onClick={() => navigate(`/users/${userData?.uid}/mutualFund`)}
									icon={<ArrowForwardIosIcon />}
								/>
							}
						/></>
				)}
				<Grid item xs={12} sm={10}>
					<Typography variant="h6">
						{'User Cumulative Investment Data (LB + EH + MC)'}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={1}>
					<Button
						variant="contained"
						onClick={async () => {
							if (uid != null && uid != undefined) {
								await usersController.lendboxPortfolioUpdate(uid)
							}
						}}
					>
						Lendbox Portfolio Update
					</Button>
				</Grid>
				<Grid item xs={12} sm={1}>
					<Button
						variant="contained"
						onClick={async () => {
							if (uid != null && uid != undefined) {
								await usersController.balanceReconciliation(uid)
							}
						}}
					>
						Balance Reconciliation
					</Button>
				</Grid>
				<DataCard
					classes={classes}
					title={'Commutative Balance'}
					value={currencyFormat(
						(userData?.balance?.lendbox?.balance ?? 0) +
						(userData?.balance?.['lendbox-fixed60']?.balance ?? 0) +
						(fundData?.['PLF-EHGPG']?.nav * (userData?.balance?.['PLF-EHGPG']?.units ?? 0)) +
						(fundData?.['PLF-MCGPG']?.nav * (userData?.balance?.['PLF-MCGPG']?.units ?? 0))
					)}
				/>
				<DataCard
					classes={classes}
					title={'Cumulative Investment'}
					value={currencyFormat(
						(userData?.balance?.lendbox?.invested ?? 0) +
						(userData?.balance?.['lendbox-fixed60']?.invested ?? 0) +
						(userData?.balance?.['PLF-EHGPG']?.invested ?? 0) +
						(userData?.balance?.['PLF-MCGPG']?.invested ?? 0)
					)}
				/>
				<DataCard
					classes={classes}
					title={'Cumulative Withdrawal'}
					value={currencyFormat(
						(userData?.balance?.lendbox?.totalWithdrawal ?? 0) +
						(userData?.balance?.['lendbox-fixed60']?.totalWithdrawal ?? 0) +
						(userData?.balance?.['PLF-EHGPG']?.totalWithdrawal ?? 0) +
						(userData?.balance?.['PLF-MCGPG']?.totalWithdrawal ?? 0)
					)}
				/>
				<DataCard
					classes={classes}
					title={'Total Mandates Executed'}
					value={(
						(userData?.balance?.lendbox?.totalMandates ?? 0) +
						(userData?.balance?.['lendbox-fixed60']?.totalMandates ?? 0) +
						(userData?.balance?.['PLF-EHGPG']?.totalMandates ?? 0) +
						(userData?.balance?.['PLF-MCGPG']?.totalMandates ?? 0)
					).toString()}
				/>
				<DataCard
					classes={classes}
					title={'Cumulative Withdrawal in Progress'}
					value={currencyFormat(
						(userData?.balance?.lendbox?.withdrawalInProgress ?? 0) +
						(userData?.balance?.['lendbox-fixed60']?.withdrawalInProgress ?? 0) +
						(userData?.balance?.['PLF-EHGPG']?.withdrawalInProgress ?? 0) +
						(userData?.balance?.['PLF-MCGPG']?.withdrawalInProgress ?? 0)
					)}
				/>
				<DataCard
					classes={classes}
					title={'Cumulative Available to Withdraw'}
					value={currencyFormat(
						(userData?.balance?.lendbox?.availableToWithdraw ?? 0) +
						(userData?.balance?.['lendbox-fixed60']?.availableToWithdraw ?? 0) +
						(userData?.balance?.['PLF-EHGPG']?.balance ?? 0) +
						(userData?.balance?.['PLF-MCGPG']?.balance ?? 0) -
						(userData?.balance?.['PLF-EHGPG']?.withdrawalInProgress ?? 0) -
						(userData?.balance?.['PLF-MCGPG']?.withdrawalInProgress ?? 0)
					)}
				/>
				<Accordion style={{ width: '98%', margin: '1%', background: '#00000000' }}>
					{/* <Title text={`${fund} Balances`} /> */}
					<AccordionSummary expandIcon={
						<ExpandMoreIcon />}>
						<Title text={'Lendbox Balances'} />
					</AccordionSummary>
					{lendboxBalanceCards('lendbox')}
				</Accordion>
				<Accordion style={{ width: '98%', margin: '1%', background: '#00000000' }}>
					{/* <Title text={`${fund} Balances`} /> */}
					<AccordionSummary expandIcon={
						<ExpandMoreIcon />}>
						<Title text={'Lendbox Fixed Balances'} />
					</AccordionSummary>
					{lendboxBalanceCards('lendbox-fixed60')}
				</Accordion>
				{
					mutualFunds.map((fund, _index) => {
						const folios = userData?.balance?.[fund]?.folios ?? {}
						return (
							<>
								<Accordion style={{ width: '98%', margin: '1%', background: '#00000000' }}>
									{/* <Title text={`${fund} Balances`} /> */}
									<AccordionSummary expandIcon={
										<ExpandMoreIcon />}>
										<Title text={`${fund} Balances`} />
									</AccordionSummary>
									{getDataCards(fund).map((card, subindex: any) => {
										return (
											<AccordionDataCard
												key={subindex}
												classes={classes}
												title={card.title}
												value={card.value}
											/>
										)
									})}
									{Object.keys(folios).map((folioNum: any) => {
										return (
											<>
												{/* <div className="cardColumn"> */}
												<Accordion style={{ width: '98%', margin: '1%' }}>
													<AccordionSummary expandIcon={
														<ExpandMoreIcon />}>
														<Title text={`Folio Number : ${folioNum}`}
														/>
														<CustomIconButton
															onClick={() => copyToClipboard(folioNum)}
															icon={<FileCopyIcon />}
														/>

													</AccordionSummary>

													{getFolioDataCards(fund, folioNum).map(
														(card, subindex: any) => {
															return (
																<AccordionDataCard
																	key={subindex}
																	classes={classes}
																	title={card.title}
																	value={card.value}
																/>
															)
														}
													)}
												</Accordion>
											</>
										)
									})}
									{/* </AccordionDetails> */}
								</Accordion >
							</>
						)
					})
				}
				{adminUserData?.isFullUsersAccess && (<>
					<Title text={'Reward'} />

					<DataCard
						classes={classes}
						title={'Reward Counter'}
						value={(userData?.rewardsCounter ?? false).toString()}
						color={
							userData?.rewardsCounter ?? false
								? Colors.system.positiveGreen400
								: Colors.system.negativeRed400
						}
					/>
					<DataCard
						classes={classes}
						title={'Reward Claim'}
						value={(userData?.rewardClaim ?? false).toString()}
						color={
							userData?.rewardClaim ?? false
								? Colors.system.positiveGreen400
								: Colors.system.negativeRed400
						}
					/>
					<DataCard
						classes={classes}
						title={'Reward Counter Roundup'}
						value={currencyFormat(userData?.rewardsCounterRoundup)}
					/>
					<DataCard
						classes={classes}
						title={'Reward Logic'}
						value={
							(userData?.active7DaysLogic ?? true ? 7 : 30).toString() + ' days'
						}
					/>
					<UserBankAccountUpdateDialog id={Number(id)} onClose={() => { setShowAddBankDialog(false) }} showAddBankDialog={showAddBankDialog} />
				</>)}
			</>
		)
	}

	function bottom() {
		switch (tabIndex) {
			case 0:
				return <UserDataView />
			case 1:
				return <BalanceView />
			case 2:
				return <TransactionsView />
			case 3:
				if (transactionMonth == null)
					return (
						<TransactionsMonth
							onClickView={(month: string) => {
								clearQuery()
								setTransactionMonth(month)
							}}
						/>
					)
				else
					return (
						<Transactions
							month={transactionMonth}
							goBackFunction={() => {
								clearQuery()
								setTransactionMonth(null)
							}}
						/>
					)

			case 4:
				return (
					<>
						<Grid item xs={12} sm={12}>
							<Tabs value={['lendbox', 'lendbox-fixed60', 'PLF-EHGPG', 'PLF-MCGPG'].indexOf(fundType ?? userData?.fundType ?? 'lendbox')} onChange={(event, value) => {
								clearQuery()
								setFundType(['lendbox', 'lendbox-fixed60', 'PLF-EHGPG', 'PLF-MCGPG'][value])
								setPortfolioMonth(null)
							}} variant="fullWidth">
								<Tab label="Lendbox" />
								<Tab label="Lendbox Fixed 60" />
								<Tab label="Navi Aggressive Hybrid Fund" />
								<Tab label="Navi Large & Midcap Fund" />
							</Tabs>
						</Grid>
						{portfolioMonth == null ? (
							<PortfolioMonth
								fundType={fundType ?? userData?.fundType ?? 'lendbox'}
								onClickView={(month: any) => {
									clearQuery()
									setPortfolioMonth(month)
								}}
							/>
						) : (
							<Portfolio
								month={portfolioMonth}
								fundType={fundType ?? userData?.fundType ?? 'lendbox'}
								goBackFunction={() => {
									clearQuery()
									setPortfolioMonth(null)
								}}
							/>
						)}
					</>
				)
			default:
				return (<></>)
		}
	}

	if (loading || userData == null) return <></>
	return (
		<Layout id={'users'}>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={12}>
					<Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth">
						<Tab label="User Data" />
						<Tab label="Balance API (BETA)" />
						<Tab label="Transactions View (BETA)" />
						<Tab label="Transactions" />
						<Tab label="Portfolio" />
					</Tabs>
				</Grid>
				{bottom()}
			</Grid>
		</Layout >
	)
}
